import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { TextureLoader, Vector3, RepeatWrapping, NearestFilter, sRGBEncoding } from 'three';
import { OrbitControls, SpotLight, AmbientLight } from '@react-three/drei';
import { useSpring, a } from '@react-spring/three';

const ImageWithDepthMap = ({ image, depthMap }) => {
  const meshRef = useRef();
  const texture = useLoader(TextureLoader, image);
  const depthTexture = useLoader(TextureLoader, depthMap);

  // Configure texture to be used as a normal map
  depthTexture.wrapS = depthTexture.wrapT = RepeatWrapping;
  depthTexture.minFilter = NearestFilter;
  depthTexture.magFilter = NearestFilter;

  const [{ rotX, rotY }, set] = useSpring(() => ({ rotX: 0, rotY: 0 }));

  useFrame(({ mouse }) => {
    set({ rotX: mouse.y / 10, rotY: mouse.x / 10 });
  });

  return (
    <a.mesh ref={meshRef} rotation-x={rotX} rotation-y={rotY}>
      <planeGeometry args={[16, 9, 512, 512]} />  {/* Higher resolution plane */}
      <meshStandardMaterial 
        map={texture} 
        displacementMap={depthTexture} 
        displacementScale={2.5} 
        normalMap={depthTexture} 
        normalScale={new Vector3(1, 1, 1)}
      />
    </a.mesh>
  );
};

const SplashScreen = ({ image, depthMap }) => {
  const canvasRef = useRef();
  const [aspect, setAspect] = useState(16 / 9);  // Default aspect ratio

  useEffect(() => {
    const handleResize = () => {
      if (canvasRef.current) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        setAspect(width / height);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleContextLost = (event) => {
      console.error('WebGL context lost:', event);
    };

    const handleContextRestored = () => {
      console.log('WebGL context restored');
    };

    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('webglcontextlost', handleContextLost);
      canvas.addEventListener('webglcontextrestored', handleContextRestored);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('webglcontextlost', handleContextLost);
        canvas.removeEventListener('webglcontextrestored', handleContextRestored);
      }
    };
  }, []);

  return (
    <Canvas 
      ref={canvasRef} 
      style={{ width: '100%', height: '100vh' }} 
      camera={{ position: new Vector3(0, 0, 5), aspect }}
      gl={{ antialias: false, toneMappingExposure: 1.5 }}
    >
      <ambientLight intensity={1.3} />
      <spotLight position={[5, 5, 5]} angle={0.3} penumbra={0.5} intensity={1} castShadow />
      <spotLight position={[-5, 5, 5]} angle={0.3} penumbra={0.5} intensity={0.5} castShadow />
      <pointLight position={[0, -5, 5]} intensity={0.2} />
      <ImageWithDepthMap image={image} depthMap={depthMap} />
      <OrbitControls enableZoom={false} enablePan={false} />
    </Canvas>
  );
};

export default SplashScreen;
