import React from 'react';
import { createRoot } from 'react-dom/client';
import SplashScreen from './SplashScreen';

const image = '/assets/screen.jpeg';
const depthMap = '/assets/depth.jpeg';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    
      <SplashScreen image={image} depthMap={depthMap} />,
    
  );
} else {
  console.error('Root container missing in index.html');
}
